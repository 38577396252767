<template>
  <a-card class="page-container">
    <a-row :gutter="24">
      <a-col :span="8">
        <a-card>
          <div slot="title">日历导航</div>
          <calendar-nav
            @select="handleCalendarSelect"
          />
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-spin :spinning="loading">
          <div class="data-block">
            <div class="data-block__hd">活动KPI数据</div>
            <div class="data-block__bd">
              <a-carousel v-if="kpiList.length > 0" arrows>
                <div slot="prevArrow" style="z-index: 1;">
                  <a-icon type="left" />
                </div>
                <div slot="nextArrow">
                  <a-icon type="right" />
                </div>

                <div v-for="(item, index) in kpiList" :key="index">
                  <a-row :gutter="16">
                    <a-col :span="8" v-for="(item2, index2) in item" :key="index2">
                      <div class="kpi-item">
                        <div class="kpi-item__hd">
                          <div class="kpi-label">KPI名称</div>
                          <div class="kpi-name">{{ item2.kpiName }}</div>
                        </div>
                        <div class="kpi-item__bd">
                          <div class="kpi-data-item">
                            <div class="kpi-data-item__label">KPI值：</div>
                            <div class="kpi-data-item__value">{{ item2.kpiValue }}％</div>
                          </div>
                          <div class="kpi-data-item">
                            <div class="kpi-data-item__label">当前值：</div>
                            <div class="kpi-data-item__value">{{ item2.currentValue }}％</div>
                          </div>
                        </div>
                        <div class="kpi-item__ft">
                          <div class="kpi-data-item">
                            <div class="kpi-data-item__label">达标率：</div>
                            <div class="kpi-data-item__value">{{ item2.rate }}%</div>
                          </div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-carousel>
              <a-empty v-else />
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>

    <a-row class="mt-24" :gutter="24">
      <a-col :span="8">
        <a-card class="collect-card">
          <a-button class="detail-btn" type="link" @click="jumpToPage">
            详情
          </a-button>
          <a-tabs v-model="activeTab" @change="handleTabsChange">
            <a-tab-pane tab="日汇总" :key="1"></a-tab-pane>
            <a-tab-pane tab="周汇总" :key="2"></a-tab-pane>
            <a-tab-pane tab="月汇总" :key="3"></a-tab-pane>
          </a-tabs>
          <a-table
            :loading="loading2"
            :columns="columns"
            :data-source="dealerSummary"
            :row-key="(record, index) => index"
            :pagination="false"
            :scroll="dealerSummary.length <= 5 ? {} : {y: 500}"
          >
            <div slot="student" slot-scope="text, record">
              <div>{{ record.userName || '--' }}</div>
              <div>{{ record.principalName || '--' }}</div>
              <div>{{ record.dealerName || '--' }}</div>
              <div></div>
            </div>
          </a-table>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-spin :spinning="loading2">
          <div class="data-block">
            <div class="data-block__hd">{{ text }}汇总直播提交数据</div>
            <div class="data-block__bd">
              <div class="data-list">
                <div class="data-item">
                  <div class="data-item__label">按时提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.submitLiveTotal }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">未提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.unSubmitLiveTotal }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">延迟提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.delaySubmitLiveTotal }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-block mt-20">
            <div class="data-block__hd">{{ text }}汇总短视频提交数据</div>
            <div class="data-block__bd">
              <div class="data-list">
                <div class="data-item">
                  <div class="data-item__label">按时提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.submitAwemeTotal }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">未提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.unSubmitAwemeTotal }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="data-item__label">延迟提交</div>
                  <div class="data-item__value">
                    {{ submitSummary.delaySubmitAwemeTotal }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import CalendarNav from '@/components/actManage/CalendarNav'
import activityApi from '@/api/activity'

const columns = [
  {
    title: '学员',
    key: 'student',
    scopedSlots: { customRender: 'student' },
  },
  {
    title: '直播',
    dataIndex: 'liveTotal',
    key: 'liveTotal'
  },
  {
    title: '短视频',
    dataIndex: 'awemeTotal',
    key: 'awemeTotal'
  }
]

export default {
  name: 'actContentOperAssi',
  components: {
    CalendarNav
  },
  data () {
    return {
      statisticsDate: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      kpiList: [],
      activeTab: 1,
      loading2: false,
      columns,
      dealerSummary: [],
      submitSummary: {
        submitLiveTotal: 0,
        unSubmitLiveTotal: 0,
        delaySubmitLiveTotal: 0,
        submitAwemeTotal: 0,
        unSubmitAwemeTotal: 0,
        delaySubmitAwemeTotal: 0
      }
    }
  },
  created () {
    this.fetchActivityKpiDay()
    this.fetchActivityProductSummary()
  },
  computed: {
    text () {
      let text = ''
      switch (this.activeTab) {
        case 1:
          text = '日'
          break
        case 2:
          text = '周'
          break
        case 3:
          text = '月'
      }
      return text
    }
  },
  methods: {
    //
    handleCalendarSelect (date) {
      this.statisticsDate = date
      this.fetchActivityKpiDay()
      this.fetchActivityProductSummary()
    },
    //
    async fetchActivityKpiDay () {
      this.loading = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          statisticsDate: this.statisticsDate
        }
        let { code, data, message } = await activityApi.fetchActivityKpiDay(params)
        
        if (code === 200) {
          if (data && data.length > 0) {
            let kpiList = [[]]

            data.forEach((item, index) => {
              if (index === 0 || index % 3 !== 0) {
                kpiList[kpiList.length - 1].push(item)
              } else {
                kpiList.push([])
                kpiList[kpiList.length - 1].push(item)  
              }
            })

            this.kpiList = kpiList
          } else {
            this.kpiList = []
          }
        } else {
          this.$message.error(message)
        }

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    //
    handleTabsChange (val) {
      this.fetchActivityProductSummary()
    },
    //
    async fetchActivityProductSummary () {
      this.loading2 = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          type: this.activeTab,
          statisticsDate: this.statisticsDate
        }
        const { code, data, message } = await activityApi.fetchActivityProductSummary(params)

        if (code === 200) {
          this.dealerSummary = data.dealerSummary
          this.submitSummary = data.submitSummary
        } else {
          this.$message.error(message)
        }

        this.loading2 = false
      } catch (e) {
        console.log(e)
        this.loading2 = false
      }
    },
    //
    jumpToPage () {
      this.$router.push({
        name: 'actManage:contentListOperAssi',
        query: {
          activityId: this.$route.query.activityId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.mt-24 {
  margin: 24px 0 0;
}

.ant-carousel {
  & .slick-arrow {
    width: 25px;
    height: 25px;
    color: #ccc;
    font-size: 25px;

    &::before {
      display: none;
    }
  }
}

.data-block {
  &__hd {
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
  }

  &__bd {
    padding: 10px 0;
  }
}

.kpi-item {
  padding: 20px;
  border: 1px solid #e8e8e8;

  &__bd {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  &__ft {
    display: flex;
    align-items: center;
    padding: 20px 0 0;
    border-top: 1px solid #e8e8e8;
  }

  .kpi-name {
    margin: 10px 0 0;
    font-size: 16px;
    font-weight: bold;
  }

  .kpi-data-item {
    display: flex;
    align-items: center;
    flex: 1;

    &__value {
      font-weight: bold;
    }
  }
}

.collect-card {
  .detail-btn {
    position: absolute;
    top: 30px;
    right: 9px;
    z-index: 1;
  }
}

.data-list {
  display: flex;
  margin: 0 0 0 -16px;
}
.data-item {
  flex: 1;
  margin: 0 0 0 16px;
  padding: 40px 20px;
  border: 1px solid #e8e8e8;

  &__value {
    margin: 10px 0 0;
    font-size: 26px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>
